/**
 * @name: token管理工具
 * @author: itmobai
 * @date: 2023-03-14 13:55
 * @description：token管理工具
 * @update: 2023-03-14 13:55
 */
import Cookie from "js-cookie"
import config from "@/config"

const tokenKey = config.project + "-admin-token"

/**
 * 存储token
 * @param token token
 */
export const setToken = (token: string) => {
  Cookie.set(tokenKey, token)
}

/**
 * 获取token
 */
export const getToken = () => {
  return Cookie.get(tokenKey)
}

/**
 * 移除token
 */
export const removeToken = () => {
  Cookie.remove(tokenKey)
}
