/**
 * @name: tabbar模块
 * @author: lili
 * @date: 2023-03-10 9:56
 * @description：tabbar模块
 * @update: 2023-03-10 9:56
 */
import { TagProps } from "@/store/tabbar/types";

const tabbar = {
  state: {
    token: '',
    cachedViews: [],
    visitedViews: [],
  },
  mutations: {

    addVisitedView: (state: any, view: any) => {
      if (state.visitedViews.some((v: any) => v.path === view.path)) return
      state.visitedViews.push(
        Object.assign({}, view, {
          title: view.meta.title || 'no-name'
        })
      )
    },
    addCachedView: (state: any, view: any) => {
      if (state.cachedViews.includes(view.name)) return
      if (view.meta && !view.meta.noCache) {
        state.cachedViews.push(view.name)
      }
    },

    delVisitedView: (state: any, view: any) => {
      for (const [i, v] of state.visitedViews.entries()) {
        if (v.path === view.path) {
          state.visitedViews.splice(i, 1)
          break
        }
      }
    },
    delCachedView: (state: any, view: any) => {
      const index = state.cachedViews.indexOf(view.name)
      index > -1 && state.cachedViews.splice(index, 1)
    },

    delOthersVisitedViews: (state: any, view: any) => {
      state.visitedViews = state.visitedViews.filter((v: any) => {
        return v.meta.affix || v.path === view.path
      })
    },
    delOthersCachedViews: (state: any, view: any) => {
      const index = state.cachedViews.indexOf(view.name)
      if (index > -1) {
        state.cachedViews = state.cachedViews.slice(index, index + 1)
      } else {
        state.cachedViews = []
      }
    },

    delAllVisitedViews: (state: any) => {
      // keep affix tags
      const affixTags = state.visitedViews.filter((tag: any) => tag.meta.affix)
      state.visitedViews = affixTags
    },
    delAllCachedViews: (state: any) => {
      state.cachedViews = []
    },
    updateVisitedView: (state: any, view: any) => {
      for (let v of state.visitedViews) {
        if (v.path === view.path) {
          v = Object.assign(v, view)
          break
        }
      }
    },

    delRightViews: (state: any, view: any) => {
      const index = state.visitedViews.findIndex((v: any) => v.path === view.path)
      if (index === -1) {
        return
      }
      state.visitedViews = state.visitedViews.filter((item: any, idx: any) => {
        if (idx <= index || (item.meta && item.meta.affix)) {
          return true
        }
        const i = state.cachedViews.indexOf(item.name)
        if (i > -1) {
          state.cachedViews.splice(i, 1)
        }
        return false
      })
    },

    delLeftViews: (state: any, view: any) => {
      const index = state.visitedViews.findIndex((v: any) => v.path === view.path)
      if (index === -1) {
        return
      }
      state.visitedViews = state.visitedViews.filter((item: any, idx: any) => {
        if (idx >= index || (item.meta && item.meta.affix)) {
          return true
        }
        const i = state.cachedViews.indexOf(item.name)
        if (i > -1) {
          state.cachedViews.splice(i, 1)
        }
        return false
      })
    }
  },

  actions: {
    addView({dispatch}: any, view: any) {
      dispatch('addVisitedView', view)
      dispatch('addCachedView', view)
    },
    addVisitedView({commit}: any, view: any) {
      commit('addVisitedView', view)
    },
    addCachedView({commit}: any, view: any) {
      commit('addCachedView', view)
    },

    delView({dispatch, state}: any, view: any) {
      return new Promise(resolve => {
        dispatch('delVisitedView', view)
        dispatch('delCachedView', view)
        resolve({
          visitedViews: [...state.visitedViews],
          cachedViews: [...state.cachedViews]
        })
      })
    },
    delVisitedView({commit, state}: any, view: any) {
      return new Promise(resolve => {
        commit('delVisitedView', view)
        resolve([...state.visitedViews])
      })
    },
    delCachedView({commit, state}: any, view: any) {
      return new Promise(resolve => {
        commit('delCachedView', view)
        resolve([...state.cachedViews])
      })
    },

    delOthersViews({dispatch, state}: any, view: any) {
      return new Promise(resolve => {
        dispatch('delOthersVisitedViews', view)
        dispatch('delOthersCachedViews', view)
        resolve({
          visitedViews: [...state.visitedViews],
          cachedViews: [...state.cachedViews]
        })
      })
    },
    delOthersVisitedViews({commit, state}: any, view: any) {
      return new Promise(resolve => {
        commit('delOthersVisitedViews', view)
        resolve([...state.visitedViews])
      })
    },
    delOthersCachedViews({commit, state}: any, view: any) {
      return new Promise(resolve => {
        commit('delOthersCachedViews', view)
        resolve([...state.cachedViews])
      })
    },

    delAllViews({dispatch, state}: any, view: any) {
      return new Promise(resolve => {
        dispatch('delAllVisitedViews', view)
        dispatch('delAllCachedViews', view)
        resolve({
          visitedViews: [...state.visitedViews],
          cachedViews: [...state.cachedViews]
        })
      })
    },
    delAllVisitedViews({commit, state}: any) {
      return new Promise(resolve => {
        commit('delAllVisitedViews')
        resolve([...state.visitedViews])
      })
    },
    delAllCachedViews({commit, state}: any) {
      return new Promise(resolve => {
        commit('delAllCachedViews')
        resolve([...state.cachedViews])
      })
    },

    updateVisitedView({ commit }: any, view: any) {
      commit('updateVisitedView', view)
    },
    delRightTags({commit, state}: any, view: any) {
      return new Promise(resolve => {
        commit('delRightViews', view)
        resolve([...state.visitedViews])
      })
    },

    delLeftTags({commit, state}: any, view: any) {
      return new Promise(resolve => {
        commit('delLeftViews', view)
        resolve([...state.visitedViews])
      })
    },
  }
}

export default tabbar
