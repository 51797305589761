import store from '@/store'

export default {
  inserted(el: any, binding: any) {
    const { value } = binding
    if (!store.getters.hasPermission(value)) {
      el.parentNode && el.parentNode.removeChild(el)
    }
  }
}
