import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import useMenuStore from '@/store/menu/index';
import store from "@/store";

Vue.use(VueRouter);

const routes: Array<RouteConfig> =  [
  {
    path: '/redirect',
    component:() => import('@/layout/index.vue'),
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect/index.vue')
      }
    ]
  },
  {
    path: '/',
    name: '/',
    component:() => import('@/layout/index.vue'),
    redirect: '/home/index',
    meta: {
      isKeepAlive: true,
    },
    children:[
      {
        path: '/home/index',
        name: 'home',
        component: () => import('../views/home/index.vue'),
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/index.vue'),
  },
]
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  // 判断用户Token是否获取&& !store.token
  if(to.path != "/login" && !store.getters.token) {
    next({name: "login", replace: true})
    return;
  }
  if(store.getters.token) {
    await store.dispatch("useUserStore/getUserInfos")
    // console.log(store.state.routerFlag,'store.state.routerFlag')
    // 判断Router有无获取
    if (!store.state.routerFlag) {
      store.commit("SET_ROUTEFLAG", true)
      await store.dispatch("useMenuStore/getMenuRole")
      await store.dispatch("loadPermission")
      next({ ...to, replace: true });
    } else {
      next();
    }
  }
  next();
});
export default router;
