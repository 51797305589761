import "core-js/stable"
import 'regenerator-runtime/runtime'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import global from './global'

Vue.use(global)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// ==== 调试代码 ====
if (location.protocol === 'http:') {
  const script = document.createElement('script')
  script.src = 'http://192.168.1.44:6752/page-spy/index.min.js'
  script.onload = () => {
    // @ts-ignore
    window.$pageSpy = new PageSpy({
      project: 'aquaculture-admin-web',
      autoRender: false
    });
    // @ts-ignore
    console.log('[LOG]:  你的设备码：' + window.$pageSpy.address.slice(0, 4))
  }
  document.body.appendChild(script)
}
// ==== 结束代码 ====
