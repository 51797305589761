/**
 * @name: 第三方接口信息
 * @author: itmobai
 * @date: 2023-04-21 17:32
 * @description：第三方接口信息
 * @update: 2023-04-21 17:32
 */
import {get} from "@/request";

/**
 * 地图查询
 * @param keyword 关键字
 */
export const mapPlaceSuggestionApi = (keyword: string) => get("/party/map/place/suggestion", {keyword})
/**
 * 经纬度解析时间
 * @param location 地址
 */
export const mapPlaceGeocoderLocationApi = (location: string) => get("/party/map/place/geocoderLocation", {location})
